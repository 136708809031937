<template>
  <div id="search" v-if="isLogin">
    <v-layout wrap>
      <v-flex xs12 sm9 md9 offset-sm3 offset-md3>
        <div class="search">
          <v-form
            ref="form"
            class="ml-3 mr-3 margin-t"
          >
            <v-text-field v-if="!isMobile"
              v-model="search"
              ref="search"
              append-icon="mdi-close"
              @input="searchData()"
              @click:append="toPage()"
            ></v-text-field>

            <v-text-field v-else
              v-model="search"
              ref="search"
              @input="searchData()"
              prepend-icon="mdi-arrow-left"
              @click:prepend="toPage()"
            ></v-text-field>
          </v-form>
        </div>
      </v-flex>
      <v-flex class="xs6 sm4 md3 pt-5 pb-5" v-for="(item, index) in shop" :key="index">
        <v-layout wrap @click="toProduct(item)">
          <v-flex xs12 class="shop-item pl-3 pr-3">
            <img v-bind:src="item.url_image" :class="isMobile ? 'shop-img-m' : 'shop-img'" alt="img">
          </v-flex>
          <v-flex xs12 style="text-align:center">
            <p class="text"> {{ item.product_name }}</p>
            <p v-if="item.harga_coret && item.harga_coret !== '0'" class="text-through"> {{ addComma(item.harga_coret) }} IDR </p>
            <p class="text"> {{ addComma(item.selling_price) }} IDR </p>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'Search',
     computed: {
      ...mapState([
        'isMobile',
        'isLogin'
      ])
    },
    data () {
      return {
        search: '',
        shop: []
      }
    },
    methods: {
      focusInput() {
        setTimeout (() => {
          this.$refs.search.focus()
        }, 100)
      },
      toPage () {
        this.$store.commit('setIsSearch', false)
        this.$router.go(-1)
      },
      searchData () {
        if (this.search && this.search.length >= 3) {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            key: this.search,
            is_offline: true
          }
          axios.get('/api/v1/pages/search')
            .then(response => {
              this.shop = response.data.data
            })
            .catch(error => {
              this.shop = []
              this.message = error.response.data.api_msg
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        } else {
          this.shop = []
        }
      }
    }, 
    mounted () {
      setTimeout(() => {
        if (!this.isLogin) {
          this.$router.push({ name: 'Home'})
        } else {
          this.focusInput()
        }
      }, 100)

      this.setMetaInfo(null, this.$route.name)
    
      this.$store.commit('setIsSearch', true)
      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setActivePage', this.$t('search'))
    }
  }
</script>

<style scoped>
  .margin-t {
    margin-top: 75px;
  }

  .search {
    color: #FFFFFF;
    background-color: white;
    position: relative;
    margin: auto;
  }

  .shop-item {
    position: relative;
    text-align: center;
  }

  .shop-img {
    max-width: 100%;
    height: auto;
    /* width: 250px;
    height: 250px;
    object-fit: cover; */
    cursor: pointer;
  }
  .shop-img:hover {
    opacity: 0.5;
  }

  .shop-img-m {
    /* width: 150px;
    height: 160px;
    object-fit: cover; */
    max-width: 100%;
    height: auto;
  }

  .text{
    font-size: 0.8rem;
    margin:0;
  }

  .text-through{
    font-size: 0.8rem;
    color: #808080;
    text-decoration: line-through;
    margin: 0;
  }

</style>
